import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  clienteSel: any;
  prodSelected: any;
  producto: any;
  subcategoria: string;
  categoria: string;
  pdf: any;

  cortinas = [
    {
      nombre: 'CADENA PLÁSTICA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FCADENA%20PLASTICA.png?alt=media&token=ad115530-bdea-4caf-895e-8ddae57c56c6'
    },
    {
      nombre: 'CONECTOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FCONECTOR.png?alt=media&token=fb591ec2-2982-4748-a3b8-5f5ff3a78411'
    },
    {
      nombre: 'GANCHO 10 CMS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%2010%20CMS.png?alt=media&token=87c7c868-c4ac-4d5b-8191-7a89ba10dccd'
    },
    {
      nombre: 'GANCHO 6 CMS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%206%20CMS.png?alt=media&token=610973de-ae36-4518-a530-88e9d8277fe5'
    },
    {
      nombre: 'GANCHO 8 CMS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%208%20CMS.png?alt=media&token=4571fd36-abaa-4a18-8bf4-3e5d1061146e'
    },
    {
      nombre: 'GANCHO C',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%20C.png?alt=media&token=bc81e6e5-195b-4289-b0ff-28cdd640f97a'
    },
    {
      nombre: 'GANCHO MINI C',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%20MINI%20C.png?alt=media&token=e5e018a8-04c6-4d2d-935c-ebccb3308eef'
    },
    {
      nombre: 'GANCHO PERA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FGANCHO%20PERA.png?alt=media&token=1c5d78e5-b827-4f4d-a39b-5674dec99299'
    },
    {
      nombre: 'MACRO-MADERA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FMACRO-MADERA.png?alt=media&token=29a834b1-83f8-47c0-a8ec-d30a74963a32'
    },
    {
      nombre: 'MANGO BASTÓN',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FMANGO%20BASTa%CC%80N.png?alt=media&token=900011c7-f26d-4a1e-b78b-35a8ca21f260'
    },
    {
      nombre: 'RODACHINA BALINES',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FRODACHINA-BALINES.png?alt=media&token=f3d4a139-e021-4e5e-a31f-eb4c00eccb0e'
    },
    {
      nombre: 'RODACHINA CARRO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FRODACHINA-CARROS.png?alt=media&token=1f375fa7-553d-4325-97c2-3fe964d94c19'
    },
    {
      nombre: 'RODACHINA CLIP',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FRODACHINA-CLIP.png?alt=media&token=4681acda-41de-45df-bb3b-a427610dd3f3'
    },
    {
      nombre: 'RODACHINA CORRIENTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FRODACHINA-CORRIENTE.png?alt=media&token=1e9888e0-0dd0-4294-808d-0ab91edccae0'
    },
    {
      nombre: 'RODACHINA MULTIUSO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FRODACHINA-MULTIUSO.png?alt=media&token=bad69f7e-7b47-4256-af50-f471609c84c0'
    },
    {
      nombre: 'TAPA PERFIL INFERIOR MULTIPERSIANAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTAPA%20PERFIL%20INFERIOR-MULTIPERSIANAS.png?alt=media&token=5d2e58f0-a088-49b0-a966-0cc1ae3b0789'
    },
    {
      nombre: 'TAPA PERFIL INFERIOR DE LUJO REDONDA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTAPA%20PERFIL%20INFERIOR.png?alt=media&token=a31334b0-a2e3-45bf-a642-698c4ba1a3f9'
    },
    {
      nombre: 'TAPA PERFIL Z',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTAPA%20PERFIL%20Z.png?alt=media&token=95c8f6fc-8a80-453f-be19-d79360f69892'
    },
    {
      nombre: 'TAPA RIEL CORRIENTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTAPA%20RIEL%20CORRIENTE.png?alt=media&token=4f5abe11-3e96-4945-b54a-456e70a92e6f'
    },
    {
      nombre: 'TAPA PLUS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTAPA.png?alt=media&token=638cf90d-fa7a-4df3-85af-d337e1106e8b'
    },
    {
      nombre: 'TERMINAL SUPERIOR RIEL TRÁFICO PESADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTERMINAL%20SUPERIOR.png?alt=media&token=f5ed8a39-7a4b-4a4f-9681-a6fe45068616'
    },
    {
      nombre: 'TOPE FRENO RIEL CLIP',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTOPE%20FRENO.png?alt=media&token=293cb522-c948-4bfe-9179-23f64d5fca08'
    },
    {
      nombre: 'CUERPO CON RODILLO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FCUERPO%20CON%20RODILLO.png?alt=media&token=5c00b02d-5eaf-43da-bdbf-6cae5a5ec950'
    },
    {
      nombre: 'SOPORTE RIEL FLEXIBLE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FSOPORTE%20RIEL%20FLEXIBLE.png?alt=media&token=7e39c3b0-9a87-4f85-aaaf-e91bd1d243e8'
    },
    {
      nombre: 'TERMINAL RIEL FLEXIBLE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/cortinas%2FTERMINAL%20RIEL%20FLEXIBLE.png?alt=media&token=9caf4410-e202-42da-bc9d-c8539b8fcb3e'
    }

  ]  
  panelJapones = [
    {
      nombre: 'BASE SOPORTE RIEL 3 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fbase%20soporte%20riel%203%20vias.png?alt=media&token=91c96250-32e8-46b7-8490-63adac1e1fb9'
    },
    {
      nombre: 'BASE SOPORTE RIEL 4 Y 5 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fbase%20soporte%20riel%204%20y%205%20vi%CC%81as.png?alt=media&token=30c02120-ea12-4877-aa32-4efb6bbdda06'
    },
    {
      nombre: 'BASE SOPORTE RIEL 6 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fbase%20soporte%20riel%206%20vias.png?alt=media&token=95f227f1-a7c1-44a3-9f18-8ae966362ab9'
    },
    {
      nombre: 'JUEGO TAPA TERMINAL CENEFA 4 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fjuego%20tapa%20terminal%20cenefa%204%20vias.png?alt=media&token=c06d81c7-cfa6-4466-8fa3-210e3b70babc'
    },
    {
      nombre: 'JUEGO TAPA TERMINAL CENEFA 5 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fjuego%20tapa%20terminal%20cenefa%205%20vias.png?alt=media&token=ed02bab1-9d2c-41da-b299-b2c5ca4d47c7'
    },
    {
      nombre: 'JUEGO TAPA TERMINAL CENEFA 6 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fjuego%20tapa%20terminal%20cenefa%206%20vias.png?alt=media&token=71c5866c-0cae-4873-b720-0318f67b4688'
    },
    {
      nombre: 'MASTER DE ARRASTRE PANEL DESLIZANTE IMPORTADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20panel%20deslizante%20importado.png?alt=media&token=b0055ba3-7b5e-41cc-b03a-d7886c72b893'
    },
    {
      nombre: 'MASTER DE ARRASTRE PANEL DESLIZANTE NACIONAL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20panel%20deslizante%20nacional.png?alt=media&token=485c3ace-ead3-463b-83b7-eb53959994d1'
    },
    {
      nombre: 'MASTER DE ARRASTRE PANEL TRADICIONAL CENEFA METALICA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20tradicional%20cenefa%20metalica.png?alt=media&token=1f7721bc-0f90-404a-b75b-2350bc02f12d'
    },
    {
      nombre: 'MASTER DE ARRASTRE PANEL TRADICIONAL CENEFA TELA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20tradicional%20cenefa%20tela.png?alt=media&token=f455d553-d641-42b1-9e78-b810b37c8d1a'
    },
    {
      nombre: 'MASTER DE ARRASTRE TRADICIONAL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20tradicional.png?alt=media&token=a1574c16-5ddb-430c-81b8-97b9921cc5d3'
    },
    {
      nombre: 'MASTER DE ARRASTRE VELCRO CENEFA METÁLICA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20velcro%20cenefa%20metalica.png?alt=media&token=71a9c667-1b21-4163-8fb3-72251e2ab268'
    },
    {
      nombre: 'MASTER DE ARRASTRE VELCRO CENEFA TELA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fmaster%20de%20arrastre%20velcro%20cenefa%20tela.png?alt=media&token=21e829fd-e288-465b-bf9f-9f96f5850baf'
    },
    {
      nombre: 'RODACHINA PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Frodachina%20panel%20deslizante.png?alt=media&token=9d5714f9-7fb3-40df-9da8-f09c692ee5be'
    },
    {
      nombre: 'RODACHINA TRADICIONAL CON RODILLO INCORPORADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Frodachina%20tradicional%20con%20rodillo%20incorporado.png?alt=media&token=17882932-b95f-48f5-8a08-1eab01e7a4be'
    },
    {
      nombre: 'RODACHINA 2 PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Frodachina2%20panel%20deslizante.png?alt=media&token=92eff95a-1096-4b26-94f7-75ad660c466e'
    },
    {
      nombre: 'RODACHINA 2 PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Frodachina3%20panel%20deslizante.png?alt=media&token=81e43607-4940-4ab3-8403-3338b44d96a2'
    },
    {
      nombre: 'TOPE DE ARRASTRE PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Frtope%20de%20arrastre%20panel%20deslizante.png?alt=media&token=4de16251-436f-43d9-8f69-f64cfca4a6b1'
    },
    {
      nombre: 'TAPA PERFIL LÁGRIMA PANEL JAPONÉS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Ftapa%20perfil%20lagrima%20panel%20japones.png?alt=media&token=8caf9c61-3f1f-48cf-9f64-8bf8e2a66dd5'
    },
    {
      nombre: 'TERMINAL DE ARRASTRE TRADICIONAL DERECHO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20de%20arrastre%20tradicional%20derecho.png?alt=media&token=d8f823de-681e-42f7-b239-fdf91bfffdd7'
    },
    {
      nombre: 'TERMINAL DE ARRASTRE TRADICIONAL IZQUIERDO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20de%20arrastre%20tradicional%20izquierdo.png?alt=media&token=0afc5be5-3329-49fa-bbae-570b1c64d49a'
    },
    {
      nombre: 'TERMINAL DOBLE PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20doble%20panel%20deslizante.png?alt=media&token=0cbaca8a-857f-4e95-b3b0-77cc4714747e'
    },
    {
      nombre: 'TERMINAL PANEL DESLIZANTE DERECHO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20panel%20deslizante%20derecho.png?alt=media&token=a8fcb1a3-4354-4e7d-8a42-a974a4fe8752'
    },
    {
      nombre: 'TERMINAL PANEL DESLIZANTE IZQUIERDO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20panel%20deslizante%20izquierdo.png?alt=media&token=67371eed-f027-4353-b086-b3f0c91e0e06'
    },
    {
      nombre: 'TERMINAL PANEL DESLIZANTE TRADICIONAL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20panel%20deslizante%20tradicional.png?alt=media&token=f538e442-5fe3-4ea1-9ce4-38794ab1434e'
    },
    {
      nombre: 'TERMINAL PERFIL DOBLE PANEL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20perfil%20doble%20panel.png?alt=media&token=dd80711e-29b1-44d1-898d-7fc740517de8'
    },
    {
      nombre: 'TERMINAL RIEL 2 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%202%20vias.png?alt=media&token=625d6ad1-53a0-49c5-b580-d32503ba85e0'
    },
    {
      nombre: 'TERMINAL RIEL 3 VÍAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%203%20vias.png?alt=media&token=7c78b30e-8580-486f-b533-db1f55b1a581'
    },
    {
      nombre: 'TERMINAL RIEL 4 VÍAS DERECHO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%204%20vias%20derecho.png?alt=media&token=5dc8debf-629b-420c-bf5e-3390e4f12c4b'
    },
    {
      nombre: 'TERMINAL RIEL 4 VÍAS IZQUIERDO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%204%20vias%20izquierdo.png?alt=media&token=bd342b5e-5570-4a51-8e65-cd1d2e93b491'
    },
    {
      nombre: 'TERMINAL RIEL 5 VÍAS DERECHO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%205%20vias%20derecho.png?alt=media&token=8fea64cc-bbab-41e2-817c-04bf50f14cd6'
    },
    {
      nombre: 'TERMINAL RIEL 5 VÍAS IZQUIERDO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%205%20vias%20izquierdo.png?alt=media&token=dd42df9a-7eeb-4dbb-a96c-ad06ed8bbeaf'
    },
    {
      nombre: 'TERMINAL RIEL 6 VÍAS DERECHO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%206%20vias%20derecho.png?alt=media&token=e06c725e-8ec2-4e34-85a8-47cc049e7f57'
    },
    {
      nombre: 'TERMINAL RIEL 6 VÍAS IZQUIERDO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Fterminal%20riel%206%20vias%20izquierdo.png?alt=media&token=01139645-5bc6-4442-941d-33a485a2c2e2'
    },
    {
      nombre: 'TOPE DE ARRASTRE TRADICIONAL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Ftope%20de%20arrastre%20tradicional.png?alt=media&token=906e0a30-1c51-4620-a92c-72a8c3ddfe85'
    },
    {
      nombre: 'TOPE DE FIJACION PANEL DESLIZANTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/panel%20japones%2Ftope%20de%20fijacion%20panel%20deslizante.png?alt=media&token=d85b3356-d2f2-47d0-9797-55f01af9e684'
    }    
  ]
  miniPersianas = [
    {
      nombre: 'BOTÓN',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FBOTa%CC%80N.png?alt=media&token=4c261358-96f2-4905-9b5d-cf8e0483b181'
    },
    {
      nombre: 'CONO No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FCONO%20No2.png?alt=media&token=953a8222-bfa3-4819-a5e2-6a2c54d807f2'
    },
    {
      nombre: 'CONO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FCONO.png?alt=media&token=9643801d-ad6d-4605-bda2-032a47f3a3f0'
    },
    {
      nombre: 'CORNAMUSA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FCORNAMUSA.png?alt=media&token=1844eb18-31be-477a-a49c-18ddea6f96f0'
    },
    {
      nombre: 'CUERPO CONO No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FCUERPO%20CONO.png?alt=media&token=ee8f8413-10f7-4e76-9f3b-e56a43949f10'
    },
    {
      nombre: 'CUERPO TERMINAL BASTÓN No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FCUERPO%20TERMINAL.png?alt=media&token=a053afcf-6a98-42e3-8ca7-5ef1694e89a2'
    },
    {
      nombre: 'ENROLLADOR ESPECIAL MECANISMO ENROLLADOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FENROLLADOR%20ESPECIAL-MECANISMO%20ENROLLADOR.png?alt=media&token=df2e07e2-4a1a-4eb3-8934-eaaa34b42f18'
    },
    {
      nombre: 'ENROLLADOR ESTANDAR MECANISMO ENROLLADOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FENROLLADOR%20STANDAR.png?alt=media&token=8be39b7a-9f1d-4f69-bc0c-7357f3fcdf77'
    },
    {
      nombre: 'FIJADOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FFIJADOR.png?alt=media&token=4816e9a0-7b72-4ca3-b169-52df01814a33'
    },
    {
      nombre: 'GANCHO BASTÓN',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FGANCHO%20BASTa%CC%80N.png?alt=media&token=42ae7a9f-5718-446b-975c-f2b2997ea657'
    },
    {
      nombre: 'IGUALADOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FIGUALADOR.png?alt=media&token=c5c401d6-df2b-4c01-b963-a88360ece223'
    },
    {
      nombre: 'MECANISMO FRENO AMERICANO ARMADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20FRENO-AMERICANO%20ARMADO.png?alt=media&token=bdbd9320-abb4-4044-84e9-94b8fa2fcc67'
    },
    {
      nombre: 'MECANISMO FRENO AMERICANO DESARMADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20FRENO-AMERICANO%20DESARMADO.png?alt=media&token=6089fef3-33a0-4d08-9106-804946e5906f'
    },
    {
      nombre: 'MECANISMO FRENO TIPO ESPAÑOL EXPORTACIÓN ARMADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20FRENO-TIPO%20ESPA%E2%80%9EOL%20EXPORTACIa%CC%A8N.png?alt=media&token=83c8a426-b72b-4c79-8ece-6fb897b5d764'
    },
    {
      nombre: 'MECANISMO FRENO TIPO ESPAÑOL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20FRENO-TIPO%20ESPA%E4%BB%A4L.png?alt=media&token=2f562fd1-21f9-43e4-a9c7-9f782c25ba1f'
    },
    {
      nombre: 'MECANISMO INCLINADOR AMERICANO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20INCLINADOR-AMERICANO.png?alt=media&token=9f61226e-9264-4630-bd02-53d68fc53db7'
    },
    {
      nombre: 'MECANISMO INCLINADOR ESPAÑOL A GRANEL',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO%20INCLINADOR-ESPA%E4%BB%A4L%20A%20GRANEL.png?alt=media&token=e4f3e2a0-6efe-4f20-b322-9995262d4094'
    },
    {
      nombre: 'MECANISMO INCLINADOR No 10',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO-INCLINADOR%20No%2010.png?alt=media&token=658db352-7aaa-4ea0-9e53-98601317fc55'
    },
    {
      nombre: 'MECANISMO INCLINADOR No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FMECANISMO-INCLINADOR%20No%202.png?alt=media&token=4b3275e5-a75b-4406-b4f8-af775fed0a79'
    },
    {
      nombre: 'TAPA CONO No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FTAPA%20CONO.png?alt=media&token=40d81889-3a65-4e7b-8059-6fd481abb414'
    },
    {
      nombre: 'TAPA TERMINAL BASTÓN No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FTAPA%20TERMINAL.png?alt=media&token=5aca7379-db73-4eed-a3a6-1f314af7f6b0'
    },
    {
      nombre: 'TERMINAL BASTÓN No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FTERMINAL%20BASTa%CC%80N.png?alt=media&token=1ee5ed44-9862-43ae-9af9-92f32d4b6dc6'
    },
    {
      nombre: 'TERMINAL INFERIOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FTERMINAL%20INFERIOR.png?alt=media&token=ed8bb8db-9e1e-4a5d-bea8-e431552035c3'
    },
    {
      nombre: 'TERMINAL SUPERIOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2FTERMINAL%20SUPERIOR.png?alt=media&token=47b19018-404a-4d88-b6f8-2e0af0e6a526'
    },
    {
      nombre: 'ÓVALO TRANSPARENTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/mini%20persianas%2Fa%CC%80VALO.png?alt=media&token=8c318eeb-945d-4eae-af00-1953e092e3ed'
    },
  ]
  persianaEnrollable = [
    {
      nombre: 'ADAPTADOR DE TUBO ENROLLABLE N15 11/8 - 11/4 BLANCO CON RANURA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FADAPTADOR%20DE%20TUBO%20ENROLLABLE%20N15-BLANCO%20CON%20RANURA.png?alt=media&token=081112fd-a74e-44aa-acdc-950cd8c8e0db'
    },
    {
      nombre: 'ADAPTADOR DE TUBO ENROLLABLE N15 11/8 - 11/4 BLANCO SIN RANURA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FADAPTADOR%20DE%20TUBO%20ENROLLABLE%20N15-BLANCO%20SIN%20RANURA.png?alt=media&token=283f9b91-67e8-48ed-a8cd-94769042e7a5'
    },
    {
      nombre: 'ADAPTADOR DE TUBO ENROLLABLE N15 11/8 - 11/2 BLANCO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FADAPTADOR%20DE%20TUBO%20ENROLLABLE%20N15-BLANCO.png?alt=media&token=74f53d6e-12fe-4f6e-a456-0441a9a91519'
    },
    {
      nombre: 'ADAPTADOR 1 1/2 A 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FADAPTADOR.png?alt=media&token=0a8b4b23-33a6-45ad-80e7-e603a237cecb'
    },
    {
      nombre: 'CADENA No 11',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FCADENA.png?alt=media&token=7b592758-5fb6-4286-9bbb-f87fdc486aa8'
    },
    {
      nombre: 'CONECTOR CADENA DOBLE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FCONECTOR.png?alt=media&token=c47f637f-4b9b-491d-8a60-4082637beff0'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL R8 11/4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20CONTROL-R8%201%201-4.png?alt=media&token=d234d640-fc40-4fe4-978b-9a52b46a6ce3'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL N16 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL%20N16%201%201-2.png?alt=media&token=251882d9-7a67-4866-821f-1b694de0b4d6'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL N8 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-%20N8%201%201-2.png?alt=media&token=75c6ba6c-91b0-412f-a601-bde9f8d57c3d'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL N8 11/4 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-%20N8%201%201-4%20(6VENAS).png?alt=media&token=1043263f-09f1-4f3f-99f8-30ea3ff7fc3d'
    },
    {
      nombre: 'JUEGO CONTROL TERMINAL N15 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-N15%201%201-2.png?alt=media&token=03d304a1-905f-47d3-bc57-5bc937d6cba6'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL N8 11/4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-N8%201%201-4.png?alt=media&token=80140ab3-6de9-48bf-9c1f-b654476b4269'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL N8 11/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-N8%201%201-8.png?alt=media&token=c7943f03-0267-4f4a-ba09-dac129793f19'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL R16',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-R16.png?alt=media&token=cd85cd91-95db-447a-9093-71a56594d84c'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL R8 11/4 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-R8%201%201-4%20(6VENAS).png?alt=media&token=dbc1a4c8-c969-448f-9352-775c788c80c5'
    },
    {
      nombre: 'JUEGO CONTROL Y TERMINAL R8 11/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20CONTROL%20Y%20TERMINAL-R8%201%201-8.png?alt=media&token=257c89e1-0590-4717-af94-7f20b85a9d9d'
    },
    {
      nombre: 'JUEGO N15 ADAPTADOR CON RANUERA DE 11/4 + SOPORTES 56MM GA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N15-ADAPTADOR%20CON%20RANURA-DE%201%201-4%20%2B%20SOPORTES%2056%20mmGALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=abf9943c-b008-4bb6-a089-06d7272f5bb8'
    },
    {
      nombre: 'JUEGO N15 ADAPTADOR TUBO DE 11/2 SOPORTES 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N15-ADAPTADOR%20TURBO%2011-2%20SOPORTES%2056%20mm%20BLANCOS.png?alt=media&token=d778aeaf-906a-4dcb-bc5c-f7d38a7b43b0'
    },
    {
      nombre: 'JUEGO N15 ADAPTADOR TUBO DE 11/2 SOPORTES 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N15-ADAPTADOR%20TURBO%2011-2%20SOPORTES%2056%20mm%20GALAVIZADOS%20%2B%202%20TAPAS.png?alt=media&token=c67a6dbd-471f-47eb-a158-36da97874bf3'
    },
    {
      nombre: 'JUEGO N15 TUBO DE 11/4 SOPORTES DE 56MM SIN RANURA BLANCO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N15-TUBO%2011-4%20SOPORTES%2056%20mm-SIN%20RANURA%20BLANCO.png?alt=media&token=078c4cf9-ea6f-4a3c-b46f-9554ef4f1d48'
    },
    {
      nombre: 'JUEGO N16 TUBO DE 11/2 BLANCO SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N16%20TURBO%2011-2%20BLANCO%20SOPORTES%2056%20mm-GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=12d1b3e0-35ab-4856-8ef2-46c7ac33647e'
    },
    {
      nombre: 'JUEGO N16 TUBO DE 11/2 BLANCO SOPORTES DE 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N16%20TURBO%2011-2%20BLANCO-SOPORTES%2056%20mm%20BLANCOS.png?alt=media&token=7a344b40-023e-4416-9edf-cbc8865deb6e'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/2 BLANCO SOPORTES DE 44MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-2%20BLANCO%20SOPORTES%2044%20mm%20BLANCOS.png?alt=media&token=0bda2bec-4526-42a7-8e47-0f83e2c38d85'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/2 BLANCO SOPORTES 44MM GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-2%20BLANCO%20SOPORTES%2044%20mm%20GALVANIZADOS.png?alt=media&token=418197c0-bd96-48eb-b89e-803a5d8ec6e0'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/2 SOPORTES DE 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-2%20SOPORTES%2056%20mm%20BLANCO.png?alt=media&token=fe58787b-f74b-4327-86e5-35d7cd8b41b7'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/2 SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-2%20SOPORTES%2056%20mm%20GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=140a81d1-26e0-486b-aa60-6a7957becf63'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 6 VENAS TUBO EN U SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20(6%20VENAS%20TUBO%20EN%20U)-SOPORTES%2056%20mm%20GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=bbf8e9f5-5397-4e60-a3d0-0ac5b5bee692'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 6 VENAS SOPORTES DE 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20(6%20VENAS)-SOPORTES%2056%20mm%20BLANCOS.png?alt=media&token=2307571f-8679-4e70-9f87-656a07182d12'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 6 VENAS SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20(6%20VENAS)-SOPORTES%2056%20mm%20GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=19e0bb10-e7e8-4e3d-a608-4aad8b292b33'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20-SOPORTES%2056%20mm%20GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=9b84ae0e-24ab-4af2-ad49-a244fb63f2ff'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 SOPORTES DE 44MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20BLANCO%20SOPORTES%2044%20mm%20BLANCOS.png?alt=media&token=901a2dc3-925e-494e-bc9d-226dc36c9184'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 BLANCO SOPORTES DE 44MM GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20BLANCO-SOPORTES%2044%20mm%20GALVANIZADOS.png?alt=media&token=6f15ed9b-b242-40e1-9fcc-16db28dcde3d'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/4 SOPORTES DE 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-4%20SOPORTES%2056%20mm%20BLANCOS.png?alt=media&token=468bfac4-c0c9-447f-8c79-a7f52c8e35c9'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/8 BLANCO SOPORTES DE 44MM GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-8%20BLANCO-SOPORTES%2044%20mm-GALVANIZADOS.png?alt=media&token=05a95cb8-d5ed-4a79-8b71-140473c2cc45'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/8 BLANCO SOPORTES DE 56MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-8%20SOPORTES%2056%20mm-BLANCO.png?alt=media&token=d06c54b9-f762-463e-b933-269e796c2428'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/8  SOPORTES DE 56MM GALVANIZADOS + 2 TAPAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8-TUBO%2011-8%20SOPORTES%2056%20mm-GALVANIZADOS%20%2B%202%20TAPAS.png?alt=media&token=09b51b30-ed6e-4203-aace-07d3c130be8b'
    },
    {
      nombre: 'JUEGO N8 TUBO DE 11/8 BLANCO SOPORTES DE 44MM BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20N8.png?alt=media&token=698186ca-f5b2-4ea0-9a5f-13fb7449ffba'
    },
    {
      nombre: 'JUEGO TAPAS CABEZAL ENROLLABLE R16',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20TAPAS%20CABEZAL-ENROLLABLE%20R16.png?alt=media&token=3817fffd-0f56-4571-9f12-2af3b604260d'
    },
    {
      nombre: 'JUEGO TAPAS CABEZAL ENROLLABLE R8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FJUEGO%20TAPAS%20CABEZAL-ENROLLABLE%20R8.png?alt=media&token=c4b7dbe3-4641-4687-9b72-51939490f94c'
    },
    {
      nombre: 'MECANISMO CONTROL N15 11/2 CON ADAPTADOR',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N15%201%201-2%20CON%20ADAPTADOR.png?alt=media&token=5507a42b-3f74-4a26-b6e3-cda992b11013'
    },
    {
      nombre: 'MECANISMO CONTROL N15 11/4 ADAPTADOR SIN RANURA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N15%201%201-4%20-ADAPTADOR%20SIN%20RANURA.png?alt=media&token=fe87574c-205f-473a-a145-92e7d8bf9fc6'
    },
    {
      nombre: 'MECANISMO CONTROL N15 11/4 ADAPTADOR CON RANURA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N15%201%201-4-ADAPTADOR%20CON%20RANURA.png?alt=media&token=0ee849e1-91c1-48c6-ac7a-ad31cd0af924'
    },
    {
      nombre: 'MECANISMO CONTROL N16 PARA TUBO DE 11/2 BLANCO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N16-PARA%20TUBO%20DE%201%201-2-BLANCO.png?alt=media&token=1f8321b0-87fd-43fe-9cec-3cd234e6c70f'
    },
    {
      nombre: 'MECANISMO CONTROL N8 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N8%201%201-2.png?alt=media&token=046d9157-45e0-4e00-8a19-010dca508b6a'
    },
    {
      nombre: 'MECANISMO CONTROL N8 11/4 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N8%201%201-4%20(6VENAS).png?alt=media&token=413dbb44-4993-4c57-aa77-cffe7bbfb29e'
    },
    {
      nombre: 'MECANISMO CONTROL N8 11/4 6 VENAS TUBO EN U',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N8%201%201-4%20(6VENAS)TUBO%20EN%20U.png?alt=media&token=aec6af2d-37fd-4229-a934-a428768d432b'
    },
    {
      nombre: 'MECANISMO CONTROL N8 11/4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N8%201%201-4.png?alt=media&token=ee83948e-6571-471b-b7f1-d86d2aa000cf'
    },
    {
      nombre: 'MECANISMO CONTROL N8 11/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-N8%201%201-8.png?alt=media&token=6c37cac0-33be-45e2-913c-6874e4497a52'
    },
    {
      nombre: 'MECANISMO CONTROL R16',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R16.png?alt=media&token=11a478e6-0ead-4e5c-a6e6-ab7565df0832'
    },
    {
      nombre: 'MECANISMO CONTROL R4 1',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R4%201.png?alt=media&token=08a6fbd8-0d6d-4eef-9f9f-bf218aa5d6cb'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R8%201%201-2.png?alt=media&token=13cd498a-efb7-45b6-89d8-920395d498be'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R8%201%201-4%20(6VENAS).png?alt=media&token=4558d3e9-77e5-48c8-bec3-f207d9ea381e'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R8%201%201-4.png?alt=media&token=c7e4c984-9dac-4939-b836-206497986f6b'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20CONTROL-R8%201%201-8.png?alt=media&token=e6fb5f18-3d00-497e-8e77-47b6f97a01b3'
    },
    {
      nombre: 'MECANISMO CONTROL R8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R16.png?alt=media&token=efac5ff1-422c-49eb-8bca-8f09be7f51bf'
    },
    {
      nombre: 'MECANISMO CONTROL R4 1 1/8 1 CONTROL 1 TERMINAL 2 SOPORTES GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R4%201%201-8%20(58084).png?alt=media&token=03da29b6-e2c2-4814-8d2f-47728ce017b3'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4 1 CONTROL 1 TERMINAL 2 SOPORTES GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-4%20(63468).png?alt=media&token=793535e6-952d-4473-82db-ff05f0aef897'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4 1 CONTROL 1 TERMINAL 2 SOPORTES GALVANIZADOS 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-4%20(64528).png?alt=media&token=e983cb82-aa60-48e7-b374-d9fb29d7cbce'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4 1 CONTROL 1 TERMINAL 2 SOPORTES BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-4%20(65710).png?alt=media&token=6fbb756b-77b7-4f5a-a140-4339c93f2a6e'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/4 1 CONTROL 1 TERMINAL 2 SOPORTES BLANCOS 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-4%20(68593).png?alt=media&token=d8085922-5cd8-4fef-8eac-c37ff180189d'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/8 1 CONTROL 1 TERMINAL 2 SOPORTES GALVANIZADOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-8%20(56048).png?alt=media&token=62a0ece5-50ed-421b-9494-0dc9340b55ef'
    },
    {
      nombre: 'MECANISMO CONTROL R8 1 1/8 1 CONTROL 1 TERMINAL 2 SOPORTES BLANCOS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20R8%201%201-8%20(66371).png?alt=media&token=aa7afdcc-aae4-4835-9b13-91ea58b8bc90'
    },
    {
      nombre: 'MECANISMO TERMINAL ENROLLABLE N15 DE 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-ENROLLABLE%20N15%20DE%201%201-2.png?alt=media&token=14bca77c-5225-4815-9c17-3e4ca41cd587'
    },
    {
      nombre: 'MECANISMO TERMINAL ENROLLABLE N15 DE 11/4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-ENROLLABLE%20N15%20DE%201%201-4.png?alt=media&token=9c6ec3c8-6aa0-423a-8617-831e8fe325b8'
    },
    {
      nombre: 'MECANISMO TERMINAL ENROLLABLE N15 DE 11/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-ENROLLABLE%20N15%20DE%201%201-8.png?alt=media&token=97ccde7e-662d-409d-9d0d-eaf8ef52b3df'
    },
    {
      nombre: 'MECANISMO TERMINAL R16',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R16.png?alt=media&token=535204ef-312a-44ca-86e5-d49d7322fcee'
    },
    {
      nombre: 'MECANISMO TERMINAL R4 11/8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R4%201%201-8.png?alt=media&token=ccb2e0d9-be3d-4c24-83ad-b84e61e6c853'
    },
    {
      nombre: 'MECANISMO TERMINAL R4 1',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R4%201.png?alt=media&token=f6931347-0fa3-4a2a-ba9f-82348a08d7d5'
    },
    {
      nombre: 'MECANISMO TERMINAL R8 11/2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R8%201%201-2.png?alt=media&token=1a293d0c-b65b-4776-ad61-7e5cf98a10a6'
    },
    {
      nombre: 'MECANISMO TERMINAL R8 11/4 6 VENAS',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R8%201%201-4%20(6VENAS).png?alt=media&token=51681610-d708-41ce-88fd-704dbb200dae'
    },
    {
      nombre: 'MECANISMO TERMINAL R8 1 1/4 PBT',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FMECANISMO%20TERMINAL-R8%201%201-4%20(PBT).png?alt=media&token=4455d9ec-9e5c-41d4-b5e2-c7fa59b95846'
    },
    {
      nombre: 'SEGURO DE CADENA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSEGURO%20DE%20CADENA.png?alt=media&token=f3ab3c49-d644-4d62-9f95-af19ef231e49'
    },
    {
      nombre: 'SOPORTE CONTROL R8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20CONTROL.png?alt=media&token=246a40a2-ba46-49aa-a462-082952120e0c'
    },
    {
      nombre: 'SOPORTE ENROLLABLE N15 DE 44MM CON PESTAÑA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20ENROLLABLE%20N15%20DE%2044%20mm-CON%20PESTA%E2%80%9EA.png?alt=media&token=333a8240-9906-4dbe-b1bf-23f197ce0b04'
    },
    {
      nombre: 'SOPORTE ENROLLABLE N15 DE 56MM',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20ENROLLABLE%20N15%20DE%2056%20mm.png?alt=media&token=ed636476-3d01-43df-bcfd-60a3e27b2bdb'
    },
    {
      nombre: 'SOPORTE METÁLICO CONTROL R16 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20MET%E7%84%A1ICO-CONTROL%20R16%202.png?alt=media&token=2a10f045-fd6d-43c6-80f9-1e51c4fbf0b0'
    },
    {
      nombre: 'SOPORTE METÁLICO TERMINAL R16 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20MET%E7%84%A1ICO-TERMINAL%20R16%202.png?alt=media&token=6a8ffa8a-ddee-44fc-a9bf-1a1515f417ee'
    },
    {
      nombre: 'SOPORTE TERMINAL R8',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20TERMINAL.png?alt=media&token=8b1ad4c6-9bd7-4d9b-81c7-884477869b7b'
    },
    {
      nombre: 'TERMINAL CADENA 11 ENROLLABLE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FTERMINAL%20CADENA%2011.png?alt=media&token=00d25f08-a72a-4abc-80c3-539283aa970d'
    },
    {
      nombre: 'TOPE CADENA ENROLLABLE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FTOPE%20CADENA.png?alt=media&token=96640ee4-db42-4053-975e-56c377fbd3a3'
    },
    {
      nombre: 'SOPORTE ENROLLABLE N15',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20enrollable%2FSOPORTE%20ENROLLABLE%20N15.png?alt=media&token=74614693-6355-47fa-850e-9ff223b1cac1'
    }


  ]
  persianaVertical = [
    {
      nombre: 'CADENA No 11',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCADENA%20N11.png?alt=media&token=2257738e-a02e-4aa8-bdc0-f4ac8ac087bf'
    },
    {
      nombre: 'CADENILLA PLÁSTICA CON MUELA ABIERTA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCADENILLA%20PL%E7%8C%B6TICA-CON%20MUELA%20ABIERTA.png?alt=media&token=652f369a-2e9f-4327-88b8-147e649a76c9'
    },
    {
      nombre: 'CADENILLA PLÁSTICA No 3 CON MUELA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCADENILLA%20PL%E7%8C%B6TICA-No%203%20CON%20MUELA.png?alt=media&token=058dc826-385b-4534-a4c9-0e6e3c6d455f'
    },
    {
      nombre: 'CADENILLA PLÁSTICA No 3 CONTÍNUA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCADENILLA%20PL%E7%8C%B6TICA-No%203%20CONTINUA.png?alt=media&token=01d15197-87e2-4d2d-9cf3-a4646da092e0'
    },
    {
      nombre: 'CLIP CELCON',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCLIP-CELCON.png?alt=media&token=e6248372-dcea-466d-96df-4fe9dc34f0ea'
    },
    {
      nombre: 'MECANISMO CONTROL No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FMECANISMO-CONTROL%20No%202.png?alt=media&token=fce6fb00-2234-45d0-8f00-7822790c8bf2'
    },
    {
      nombre: 'MECANISMO TERMINAL No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FMECANISMO-TERMINAL%20No%202.png?alt=media&token=66209472-fa1a-482c-bfee-d54db2775154'
    },
    {
      nombre: 'PESA LAMA ESPECIAL PIN GRUESO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FPESA%20LAMA%20ESPECIAL.png?alt=media&token=5c7937c0-bebd-4901-8014-c41e9b018881'
    },
    {
      nombre: 'PESA LAMA SIN PLATINA PIN DELGADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FPESA%20LAMA%20SIN%20PLATINA-PIN%20DELGADO.png?alt=media&token=2a8b9823-ef4c-420b-9652-9f94d6438a97'
    },
    {
      nombre: 'PESA LAMA SIN PLATINA PIN GRUESO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FPESA%20LAMA%20SIN%20PLATINA-PIN%20GRUESO.png?alt=media&token=fd26996d-75c0-481a-a7a7-affbb50c745a'
    },
    {
      nombre: 'PORTA LAMA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FPORTA-LAMA.png?alt=media&token=926e9197-3ec4-49c1-bb72-45a62ea6792f'
    },
    {
      nombre: 'SEGURO DOBLE CADENA No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FSEGURO%20DOBLE%20-CADENA%20No%202.png?alt=media&token=443fd506-bd90-4617-940b-df00ca7a6020'
    },
    {
      nombre: 'SEGURO DOBLE CADENA CARCASA ENSAMBLADA + SOPORTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FSEGURO%20DOBLE%20CADENA.png?alt=media&token=2e65bf57-7d25-471c-a778-bc7e30014a36'
    },
    {
      nombre: 'SOPORTE EJE DENTADO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FSOPORTE-EJE%20DENTADO.png?alt=media&token=312d94d0-5eba-4e6f-88f2-a92eff9caa6c'
    },
    {
      nombre: 'STEAM CON REGLETE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FSTEAM%20CON%20REGLETE.png?alt=media&token=ce74f745-267d-4408-8c7d-8c72ddeadacc'
    },
    {
      nombre: 'TENSOR DE PESA No 2',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FTENSOR%20DE%20PESA%20No%202.png?alt=media&token=21e7a73f-2a1a-4cfa-b01d-f6d3d46906e7'
    },
    {
      nombre: 'TENSOR DE PESA No 4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FTENSOR%20DE%20PESA%20No%204.png?alt=media&token=17309c5f-6d2b-4eff-9964-36560e4c31b0'
    },
    {
      nombre: 'TENSOR DE PESA No 1',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FTENSOR%20DE%20PESA-No%201.png?alt=media&token=e7e93558-b4ff-4a52-a625-40ff54cb61ef'
    },
    {
      nombre: 'CADENA PLÁSTICA No 4',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FCADENA%20PLASTICA%20No4.png?alt=media&token=03366a8e-aa9e-4b8c-85c2-3bba791dcdc1'
    },
    {
      nombre: 'PIÑON STEAM C/TOPE GRUESO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FPIN%CC%83ON%20STEAM%20C%3ATOPE%20GRUESO.png?alt=media&token=f2102f2b-38ec-43e8-b656-7a314abd3968'
    },
    {
      nombre: 'TENSOR DE CADENA No6',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FTENSOR%20DE%20CADENA%20No6.png?alt=media&token=897a8443-2eb3-4f8a-8d11-76174a191041'
    },
    {
      nombre: 'TENSOR FUTURE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20vertical%2FTENSOR%20FUTURE%20No4.png?alt=media&token=684df189-9ce8-45f9-b5a8-880e0cd1c0a8'
    }
  ]

  persianaRomana = [
    {
      nombre: 'ARGOLLA PERSIANA PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FARGOLLA%20PERSIANA.png?alt=media&token=0ccbfe8f-3c93-4a6f-abfc-b4438ec13e0d'
    },
    {
      nombre: 'ARGOLLA TERMINAL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FARGOLLA%20TERMINAL.png?alt=media&token=b4223d4e-0003-4d40-95d7-83570d975bf9'
    },
    {
      nombre: 'GUIA CORDEL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FGUIA%20CORDEL.png?alt=media&token=9eee032a-e1c3-437e-915d-a431853bed7b'
    },
    {
      nombre: 'IGUALADOR CORDÓN PÉNDULO ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FIGUALADOR%20CORDa%CC%80N.png?alt=media&token=919b4cc2-2e75-475f-a5b2-eb01198c8f33'
    },
    {
      nombre: 'MECANISMO FRENO PERSIANA ROMANA RODILLO METÁLICO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FMECANISMO%20FRENO.png?alt=media&token=2763b235-e54e-4bf7-99d7-7c98ff4fe289'
    },
    {
      nombre: 'PASAHILOS PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FPASAHILOS.png?alt=media&token=dbff7bc0-c2ee-45ea-8aed-ca088671038b'
    },
    {
      nombre: 'SOPORTE CORDON PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FSOPORTE%20CORDa%CC%80N.png?alt=media&token=ae953a90-c743-46a3-82bf-25f5cc344078'
    },
    {
      nombre: 'TAPA CABEZAL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20CABEZAL.png?alt=media&token=14a28a22-b56a-4d2b-b9f1-66f40246d659'
    },
    {
      nombre: 'TAPA LATERAL ROMANA BELLA VISTA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20LATERAL%20ROMANA.png?alt=media&token=a43e8e7f-1f0a-4fff-9b76-ba20d3438e93'
    },
    {
      nombre: 'TAPA PERFIL DE LUJO CUADRADA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20PERFIL%20INFERIOR.png?alt=media&token=a1246127-d973-4ada-854f-dfeb8049335e'
    },
    {
      nombre: 'TAPA PERFIL NUEVO PERSIANA ROMANA EUROPEO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20PERFIL%20NUEVO.png?alt=media&token=f20528c7-3da1-4b63-87cf-7d88e7ddcee2'
    },
    {
      nombre: 'TAPA PERFIL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20PERFIL.png?alt=media&token=f4c9383a-e918-4c02-bdbd-49dd83685197'
    },
    {
      nombre: 'TAPA RIEL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTAPA%20RIEL.png?alt=media&token=3e5c9ed3-dca4-479c-8600-9bfd3b343d69'
    },
    {
      nombre: 'TERMINAL PERFIL PERSIANA ROMANA',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20romana%2FTERMINAL%20PERFIL.png?alt=media&token=21f00fc3-4449-4ef6-95f5-cf01ea6507c3'
    }
  ]
  persianaSheer = [
    {
      nombre: 'CONECTOR TRANSPARENTE',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FCONECTOR.png?alt=media&token=d4e4065e-8742-49f7-916a-f3fc24aa1746'
    },
    {
      nombre: 'JUEGO TAPA TRIANGULAR TAPA REDONDA SHEER',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FJUEGO%20TAPA%20TRIANGULAR.png?alt=media&token=bdb90b43-56d4-4cab-8b1c-f7afe8d0db28'
    },
    {
      nombre: 'ROTOR TAPA PERFIL SHEER PREMIUM',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FROTOR%20TAPA%20PERFIL.png?alt=media&token=084d7c6e-a8c6-4d3e-82c5-39d0ab9cb4a2'
    },
    {
      nombre: 'TAPA PERFIL SHEER PREMIUM',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FTAPA%20PERFIL%20SHEER.png?alt=media&token=6c52ad10-82b8-4d70-9a0f-7bfa3038af81'
    },
    {
      nombre: 'TAPA REDONDA SHEER',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FTAPA%20REDONDA.png?alt=media&token=a9a363d9-9954-4db5-9368-1b79fe865d11'
    },
    {
      nombre: 'TAPA TRIANGULAR SHEER',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FTAPA%20TRIANGULAR.png?alt=media&token=e0042d7c-3c5e-4bfa-9629-01338d98514c'
    },
    {
      nombre: 'TERMINAL INFERIOR SHEER LARGO',
      imagen: 'https://firebasestorage.googleapis.com/v0/b/normarh-6e67f.appspot.com/o/persiana%20sheer%2FTERMINAL%20INFERIOR.png?alt=media&token=6cf73e07-3843-4fb9-a7e9-6b77c2d99908'
    }
  ]

  constructor(private db: AngularFirestore) {
   
  }

  

  cargarClientes(){
      return this.db.collection('clientes')
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }

  cargarCotizaciones(){
    return this.db.collection('cotizaciones')
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }

  crearCotizacion(cotizacion: any){
    return this.db.collection('cotizaciones').add(cotizacion);
  }

  actualizarEstadoSolicitud(idSolicitud: any){
    return this.db.collection('solicitudes').doc(idSolicitud).update({estado: 'Cotizado'});
  }

  loadSolicitudes(){
    return this.db.collection('solicitudes', ref => ref
                  .where('estado','==', 'Enviado'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }
  loadSolicitudesCortinas(){
    return this.db.collection('solicitudesCortinas')
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }
  cargarCortinasId(idCort: string){
    return this.db.collection('cortinas').doc(idCort).valueChanges();
  }

  verificaCliente(num: any){
    return this.db.collection('clientes', ref => ref
                  .where('numero', '==', num))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }

  crearCliente(cliente: any){
    return this.db.collection('clientes').add(cliente);
  }

  envioSolicitudCotizacion(solicitud: any){
    return this.db.collection('solicitudes').add(solicitud);
  }

  listaProductosPorCatySub(cat: any, subc: any){
    return this.db.collection('productos')
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }
  listaProductos(){
    return this.db.collection('productos', ref => ref
                  .orderBy('producto', 'desc'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }
  cargarproductobyid(idProd: any){
    return this.db.collection('productos').doc(idProd).valueChanges();
  }

  cargarProductoPorNombre(producto: any){
    return this.db.collection('productos', ref => ref
                  .where('producto','==',producto))
                  .valueChanges();
  }

  cargarusuarios(){
    return this.db.collection('usuarios')
    .snapshotChanges()
    .pipe(
      map(actions =>
      actions.map(resp => {
      const data = resp.payload.doc.data() as any;
      const id = resp.payload.doc.id;
      return {id, ...data};
      }))
    );
  }

  cargarUsuarioPorEmail(email: any){
    return this.db.collection('usuarios', ref => ref
    .where('email','==',email))
    .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }

  actualizarPerfil(idUsuario: any, user: any){
    return this.db.collection('usuarios').doc(idUsuario).update(user);
  }

  solicitudesCortinas(solicitud: any){
    return this.db.collection('solicitudesCortinas').add(solicitud);
  }

  eliminarSolicitudCortinas(idSol: any){
    return this.db.collection('solicitudesCortinas').doc(idSol).delete();
  }

  crearCotizacionCortinas(cotizacion: any){
    return this.db.collection('cotizacionCortinas').add(cotizacion);
  }

  actualizaEstadoSolicitudCortinas(idSolicitud){
    return this.db.collection('solicitudesCortinas').doc(idSolicitud).update({estado: 'cotizado'});
  }
  cargarCotizacionesCortinas(){
    return this.db.collection('cotizacionCortinas')
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                    actions.map(resp => {
                    const data = resp.payload.doc.data() as any;
                    const id = resp.payload.doc.id;
                    return {id, ...data};
                    }))
                  );
  }
}
